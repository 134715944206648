import React from "react";
import { Route } from "react-router-dom";

const Block = ({
  visual,
  bgFix,
  film,
  book,
  height,
  ...rest
}) => {
  return (
    <div
      className="block"
      style={{
        backgroundImage: visual ? `url("./images/block-${visual}.png")` : "",
        minHeight: height ? `${height}px` : "fit-content",
        backgroundPosition: bgFix ? "auto" : "center center",
      }}
    >
      {film === "dark" ? (
        <>
          <div className="film"></div>
          <div className="film"></div>
        </>
      ) : film === "light" ? (
        <>
          <div className="light-film"></div>
          <div className="light-film"></div>
          <div className="light-film"></div>
        </>
      ) : (
        ""
      )}
      {rest && (
        <>
          <div className="container">
            <div className="block-content">
              <Route {...rest} />
            </div>
          </div>
          {book && (
            <img className="book" alt="book" src="./images/icon-pages.png" />
          )}
        </>
      )}
    </div>
  );
};

export default Block;
