import React from "react";
import Block from "../components/Block";
import Breathing from "../components/Breathing";
import Landing from "../components/Landing";
import Section from "../components/Section";
import Showcase from "../components/Showcase";
import Product from "../components/Product";

const OurServicesScreen = () => {
  return (
    <>
      <Landing
        bg="services"
        heading="by educators, for educators"
        subheading="curriculum developed by teachers"
        align="right"
      />
      <Block visual="blank">
        <Section
          bar
          barColor="green"
          subheading="grade specific coordination"
          heading="about our k-12 services"
          headingColor="white"
          copies={[
            "With the publishing of Historica Canada’s 2021 Canadian History Report Card, it is clear that there is a greater need for more work to be done in lesson planning, adapting to new technologies, and community building between classrooms and cultural institutions. The driving force behind Forager Education is to create those connections and to bring together institutions, technology solutions, and a dedicated team of teachers that have the first-hand experience to know exactly how to engage with students and keep their attention.",
            "We believe that educational content should be in the voice of those the content relates. The main recommendation of Historica’s report is that students need more opportunities to connect with local histories that reflect the full diversity of their communities. Our specialty is working with cultural institutions to utilize their unique collections to address large curriculum themes but with a localized perspective. Engaging students to think critically and make ethical judgements.",
          ]}
          copiesColor="white"
          btn="read the report"
          anchor="https://www.historicacanada.ca/sites/default/files/PDF/history-report-card-2021_summary-report.pdf"
        >
          <></>
        </Section>
      </Block>
      <Breathing
        color="green"
        text="If you are needing help taking your educational programming to the next level and monetizing your efforts to provide quality programming in a sustainable manner, contact us today!"
        btn="contact us"
        link="contact"
      />
      <Showcase>
        {[
          {
            heading: "curriculum review",
            description:
              "Most organizations have some educational programs developed already, but depending on who made them, when they were developed, and for what grade, they might need a second glance. Forager’s Curriculum Coordinators live and breathe the provincial and territorial curriculum. They will work with you to review your existing programs to ensure they match the curriculum, are linked with the right grades and are optimized for student engagement.",
          },
          {
            heading: "lesson plan development",
            description:
              "Your institution specializes in a unique aspect of our shared history, let’s share that with the classroom. If you don’t know where to begin or are simply wanting extra help, Forager’s Curriculum Development Team will work with your institution to develop a complete curriculum-connected lesson plan ready to use for direct engagement with classes. Depending on your requirements, these can be simple PDFs for teachers to use or complex interactive programs that involve a complete set of activities for classes to really delve deep into the subject matter.",
          },
          {
            heading: "field trip programming",
            description:
              "In-person learning is a great way to engage with students and let them learn without feeling like they are being lectured on a topic. Forager’s Experience Team will take your idea and help craft activities and discussions relating to your collection in a fun and engaging way. The complete program will be developed along with a final report turned over with scripts and instructions for your in-house team to effectively run this program.",
          },
          {
            heading: "in-class presentations development",
            description:
              "Why make the class travel to your museum when you can bring the museum to the classroom. Forager’s Experience Team will work with your curators to develop a travelling program. Building off the government curriculum, we will create an engaging in-class experience for students to learn directly from the experts in your organization. Scripts, visuals, and other interactive activities will be developed for your team to know exactly what to do.",
          },
          {
            heading: "learning kit creation",
            description:
              "Not all cultural institutions are reachable for in-person learning. With Forager Education’s Learning Kit Creation, your museum, art gallery, or cultural centre can develop an in-class learning experience for teachers to conduct themselves. These boxes will be complete with a detailed instruction booklet for the teacher to understand the program, activities, games, and any other physical materials that will bring the curriculum to life through your unique institution’s collection.",
          },
        ].map((ele, index) => (
          <Product
            key={index}
            type="service"
            heading={ele.heading}
            visual={ele.heading.split(" ").join("-")}
            description={ele.description}
            swap={index % 2 === 1 && true}
          />
        ))}
      </Showcase>
      <Block visual="need_funding" film="dark" height="640">
        <Section
          bar
          barColor="green"
          heading="need funding?"
          headingColor="green"
          copies={[
            "We understand that funding is usually the main consideration when undertaking a new project. For this reason, Forager Education’s Partnership Coordinators are up to date with all the federal, provincial/territorial, regional, and private funding opportunities to let you know where you can go looking for the money to get your curriculum project off the ground.",
            "If you are a partner of Forager Education through The VR Voyage Classroom, not only do you get discounts on Forager Education services, but you also get free grant writing services to help you bring together the funds for us to help you on your project.",
            "Let us help you find the funding to take your programming to the next level.",
          ]}
          copiesColor="white"
          btn="sign up today"
          link="contact"
        >
          <></>
        </Section>
      </Block>
    </>
  );
};

export default OurServicesScreen;
