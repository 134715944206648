import React from "react";
import Block from "../components/Block";
import Breathing from "../components/Breathing";
import { features, vrcPartners } from "../components/data";
import Landing from "../components/Landing";
import Product from "../components/Product";
import Section from "../components/Section";
import SectionItem from "../components/SectionItem";
import Showcase from "../components/Showcase";
import { SpecialThanks } from "../components/SpecialThanks";

const TheVRCScreen = () => {
  return (
    <>
      <Landing
        bg="vrc"
        heading="the vr voyage classroom"
        subheading="bringing the world to students"
        vrc
        btn="visit the vr voyage classroom"
        anchor="https://www.vrvclassroom.com/"
      />
      <Breathing
        color="green"
        text="We have developed a community platform for teachers and students to access professionally developed educational content from museums, cultural centres, science centres, galleries, and more. Field trips, presentations, and digital resources to bring first-hand knowledge of topics ranging from ancient history to modern politics, plate tectonics to habitat restoration direct to the classroom."
      />
      <Block visual="outlet_partner_programming" film="dark">
        <Section
          bar
          barColor="green"
          subheading="connections to BC schools"
          heading="an outlet for partner programming"
          headingColor="white"
          copies={[
            "The VR Voyage Classroom is a community platform first and foremost. By bringing together the unique stories that institutions and experts around Canada have to share in one centralized place, we are able to reach out to classrooms together and build exposure for the sector as a whole. Forager Education and provincial/territorial museum associations attract the attention of school districts and teachers so that partners can focus on producing exceptional educational programming without worrying about how to get it in front of their audience.",
            "It is a free platform for partners to take part in. There are no joining fees and no hosting fees to worry about. The platform benefits when partner content is utilized. As more experiences are listed and more content published, classrooms will be interested in taking part and the community as a whole will grow and develop into something much stronger through the monetized content.",
            "As the partner network grows, Forager Education is developing more partner benefits to maximize the support systems that it can offer. As well as discounted services offered by other Forager network brands, there are two main supports offered to partners of The VR Voyage Classroom platform.",
          ]}
          copiesColor="white"
        >
          <div className="section-items h-section-items">
            <SectionItem
              left
              icon="training"
              heading="training opportunities"
              body="Partners have the opportunity to take part in training sessions about how to host virtual experiences and adapt their in-person programming. These sessions teach new equipment options and professional techniques for what is needed to keep connected with classes outside their communities. Partnership Coordinators offer one-on-one support, but with larger themes, we organize training sessions for multiple partners to attend at the same time."
            />
            <SectionItem
              left
              icon="funding"
              heading="funding opportunities"
              body="Forager’s grant writing team works with our key partners to identify funding opportunities to alleviate the financial burden of creating digital educational materials for our partners. Wherever possible, we work with the museum associations to submit grants to provide funding for individual partners as part of larger projects that benefit the industry as a whole. Our approach is to work within a community of institutions to use successes from one partner to create opportunities for another."
            />
          </div>
        </Section>
      </Block>
      <SpecialThanks
        heading="who we work with"
        bgColor="navy"
        collection={vrcPartners}
        count
      />
      {features && features.length > 0 && (
        <Showcase>
          {features.map((ele, index) => (
            <Product
              key={index}
              type="feature"
              heading={ele.heading}
              visual={ele.heading}
              description={ele.description}
              swap={index % 2 === 1 && true}
            />
          ))}
        </Showcase>
      )}
    </>
  );
};

export default TheVRCScreen;
