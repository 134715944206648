import React from "react";
import { Link } from "react-router-dom";

const Breathing = ({ color, text, btn, link }) => {
  return (
    <div
      className="breathing"
      style={{ backgroundColor: `var(--var-color-fed-${color})` }}
    >
      <div className="container">
        <div className="breathing-content">
          <p>{text}</p>
          {btn && (
            <Link className="btn" to={`/${link}`}>
              {btn}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Breathing;
