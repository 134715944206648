import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  listContactMails,
  deleteContactMail,
} from "../actions/contactMailActions";

import Block from "../components/Block";
import Section from "../components/Section";
import Loader from "../components/Loader";
import Message from "../components/Message";

const AdminScreen = () => {
  const dispatch = useDispatch();

  const [admin, setAdmin] = useState(false);

  const [password, setPassword] = useState(``);
  const passwordHandler = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };
  const submitHandler = (e) => {
    if (password === `k2z#TDs^J$r34s5F`) {
      setAdmin(true);
    }
  };

  const contactMailList = useSelector((state) => state.contactMailList);
  const { loading, error, contactMails } = contactMailList;

  const contactMailDelete = useSelector((state) => state.contactMailDelete);
  const { loading: loadingDel, error: errorDel, success } = contactMailDelete;

  useEffect(() => {
    if (admin) {
      if (!contactMails) {
        dispatch(listContactMails());
      }
    }
  }, [dispatch, admin, contactMails]);

  useEffect(() => {
    dispatch(listContactMails());
  }, [dispatch, success]);

  const deleteHandler = (e) => {
    e.preventDefault();
    if (admin) {
      dispatch(deleteContactMail(e.target.id));
    }
  };

  return (
    <>
      {admin ? (
        <>
          {loadingDel && <Loader />}
          {errorDel && <Message body="Failed to delete the mail" />}
          {success && <Message body="Mail deleted successfully" type="info" />}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message body="Failed to load mails" />
          ) : (
            contactMails && (
              <div style={{ paddingTop: "100px" }}>
                {contactMails.length > 0 ? (
                  <Block>
                    {contactMails.map((ele, index) => (
                      <Section
                        key={index}
                        bar
                        barColor="green"
                        subheading={`from: ${ele.name}`}
                        heading={`reason: ${ele.reason}`}
                        copies={[
                          `subject: ${ele.subject}`,
                          ele.message,
                          `email: ${ele.email}`,
                          `date: ${ele.createdAt.substring(0, 10)}`,
                        ]}
                      >
                        <button
                          id={ele._id}
                          className="btn"
                          onClick={deleteHandler}
                        >
                          delete message
                        </button>
                      </Section>
                    ))}
                  </Block>
                ) : (
                  <div style={{ height: "100vh" }}>
                    <Block>
                      <Section bar barColor="green" subheading={`No mails yet`}>
                        <></>
                      </Section>
                    </Block>
                  </div>
                )}
              </div>
            )
          )}
        </>
      ) : (
        <>
          <div
            className="container"
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <form onSubmit={submitHandler} className="contact-form">
              <div className="input">
                <div className="bar"></div>
                <p className="input-title">password:</p>
                <input
                  type="password"
                  value={password}
                  onChange={passwordHandler}
                  placeholder="enter password here"
                  required
                />
              </div>
              <button className="btn" onClick={submitHandler}>
                submit
              </button>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default AdminScreen;
