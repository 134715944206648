export const navMenu = [
  "the vr voyage classroom",
  "work with us",
  // "press",
  "contact",
];

export const navAboutSubMenu = [
  "our story",
  "our services",
  // "our portfolio",
  // "team",
];

export const pageLandingSettings = [
  "heading",
  "subheading",
  "visual",
  "align",
  "btn",
  "link",
];

export const testimonials = [
  {
    name: "James Clear",
    position: "client 1",
    body: " just started using [product or service] and it has been [key benefit]. I feel [emotional response]. I would definitely say [recommendation or response to product/service]",
  },
  {
    name: "John Doe",
    position: "client 2",
    body: "I immediately knew I had a [customer problem]. I needed this resolved [product or service competitive advantage]. [Your business name] did it, and I [overall satisfaction].",
  },
  {
    name: "Kate Smith",
    position: "client 3",
    body: "[Business name] was [positive attribute]. The team helped me [problem the business solved]. I had a problem with [key challenge], but they took care of it right away.",
  },
];

export const valuedPartners = [
  {
    name: "futurpreneur canada",
    link: "https://www.futurpreneur.ca/en/",
  },
  {
    name: "british columbia museums association",
    link: "https://museum.bc.ca/",
  },
  {
    name: "business bevelopment bank of canada",
    link: "https://www.bdc.ca/en",
  },
  {
    name: "canada council for the arts",
    link: "https://canadacouncil.ca/",
  },
];

export const vrcPartners = [
  {
    name: "barkerville",
    link: "",
  },
  {
    name: "coquitlam heritage",
    link: "",
  },
  {
    name: "creston museum",
    link: "",
  },
  {
    name: "doukhobor discovery centre",
    link: "",
  },
  {
    name: "lake country",
    link: "",
  },
  {
    name: "osoyoos desert centre",
    link: "",
  },
  {
    name: "roedde house museum",
    link: "",
  },
  {
    name: "squamish lilwat",
    link: "",
  },
  {
    name: "vancouver holocaust education centre",
    link: "",
  },
];

export const footerLinks = [
  "homepage",
  "our story",
  "our services",
  // "our portfolio",
  // "team",
  "the vr voyage classroom",
  "work with us",
  // "press",
  "contact",
];

export const footerSocials = [
  {
    icon: "linkedin",
    link: "https://www.linkedin.com/company/foragered/",
  },
  // { icon: "twitter", link: "https://twitter.com/foragernet" },
  // { icon: "instagram", link: "https://www.instagram.com/foragernet/" },
  // { icon: "facebook", link: "https://www.facebook.com/foragernet" },
  // { icon: "youtube", link: "https://www.youtube.com/@ForagerNetwork" },
  // { icon: "tiktok", link: "https://www.tiktok.com/@foragernet" },
];

export const services = [
  {
    heading: "curriculum review",
    description:
      "Most organizations have some educational programs developed already, but depending on who made them, when they were developed, and for what grade, they might need a second glance. Forager’s Curriculum Coordinators live and breathe the provincial and territorial curriculum. They will work with you to review your existing programs to ensure they match the curriculum, are linked with the right grades and are optimized for student engagement.",
  },
  {
    heading: "lesson plan development",
    description:
      "Your institution specializes in a unique aspect of our shared history, let’s share that with the classroom. If you don’t know where to begin or are simply wanting extra help, Forager’s Curriculum Development Team will work with your institution to develop a complete curriculum-connected lesson plan ready to use for direct engagement with classes. Depending on your requirements, these can be simple PDFs for teachers to use or complex interactive programs that involve a complete set of activities for classes to really delve deep into the subject matter.",
  },
  {
    heading: "field trip programming",
    description:
      "In-person learning is a great way to engage with students and let them learn without feeling like they are being lectured on a topic. Forager’s Experience Team will take your idea and help craft activities and discussions relating to your collection in a fun and engaging way. The complete program will be developed along with a final report turned over with scripts and instructions for your in-house team to effectively run this program.",
  },
  {
    heading: "in-class presentations development",
    description:
      "Why make the class travel to your museum when you can bring the museum to the classroom. Forager’s Experience Team will work with your curators to develop a travelling program. Building off the government curriculum, we will create an engaging in-class experience for students to learn directly from the experts in your organization. Scripts, visuals, and other interactive activities will be developed for your team to know exactly what to do.",
  },
  {
    heading: "learning kit creation",
    description:
      "Not all cultural institutions are reachable for in-person learning. With Forager Education’s Learning Kit Creation, your museum, art gallery, or cultural centre can develop an in-class learning experience for teachers to conduct themselves. These boxes will be complete with a detailed instruction booklet for the teacher to understand the program, activities, games, and any other physical materials that will bring the curriculum to life through your unique institution’s collection.",
  },
];

export const team = [
  {
    heading: "bryce mathew watts",
    subheading: "managing director",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque elementum ullamcorper neque eget aliquam. Vivamus in tincidunt erat, a lacinia nunc. Morbi mollis imperdiet felis sit amet sagittis. Praesent non sapien tempus, efficitur lectus nec, ultrices eros. Sed eget turpis aliquam, ultricies risus at, efficitur ante. Morbi hendrerit odio in.",
  },
  {
    heading: "bryce mathew watts 2",
    subheading: "managing director",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque elementum ullamcorper neque eget aliquam. Vivamus in tincidunt erat, a lacinia nunc. Morbi mollis imperdiet felis sit amet sagittis. Praesent non sapien tempus, efficitur lectus nec, ultrices eros. Sed eget turpis aliquam, ultricies risus at, efficitur ante. Morbi hendrerit odio in.",
  },
  {
    heading: "bryce mathew watts 3",
    subheading: "managing director",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque elementum ullamcorper neque eget aliquam. Vivamus in tincidunt erat, a lacinia nunc. Morbi mollis imperdiet felis sit amet sagittis. Praesent non sapien tempus, efficitur lectus nec, ultrices eros. Sed eget turpis aliquam, ultricies risus at, efficitur ante. Morbi hendrerit odio in.",
  },
];

export const jobs = [
  {
    _id: "6C9AB7192EE4956880E6D471",
    title: "Account Manager",
    description: [
      "Are you looking to work within a small but exciting startup in the EdTech sector? Do you have a passion for creating connections between communities and finding innovative ways to solve problems? Forager Education is looking for a Partnership Coordinator to join our remote team and help bring together the cultural sector in Canada by taking part in The VR Voyage Classroom platform. Our business has been a finalist in international startup competitions and travelled to the G20 YEA Summit as an official Canadian delegate. Now, we are looking for a charismatic individual to take over the responsibility for signing on new partners and maintaining existing relationships.",
      "The Partnership Coordinator will be in charge of outreach and onboarding new partners. Our partners are museums, cultural centres, heritage sites, and science centres across Canada and we connect them directly with schools to support and promote their programming. You will be working directly with the Program Director to take this business to the next level of its development. As part of the growing partner support network, there are many Forager brands that offer services to our partners. One task for the Partnership Coordinator is to introduce these supports to partners and encourage them to utilize these extra services.",
      "Successful candidates will have an interest in EdTech, the cultural sector, and a passion for the startup ecosystem. This isn’t a standard job and needs someone that can think on their feet, can adapt their pitch to gain more buy-in, and be adaptive to an ever-changing working environment.",
    ],
    responsibilities: [
      "Plan and implement the outreach to 200+ institutions across BC.",
      "Conduct phone, video, and email outreach and facilitate pitch meetings with the Program Director.",
      "Use in-house PMS to follow up with potential partners and answer any program-related questions.",
      "Send out and collect onboarding materials from new partners.",
      "Conduct info sessions with new partners to ensure they know what to expect.",
    ],
    requirements: [
      "Bachelor’s degree in business management, marketing, humanities, or related field.",
      "Previous experience in sales and marketing.",
      "Ability to build relationships among partners and employees.",
      "Excellent communication skills.",
      "Professional-level English writing and speaking skills.",
      "Solid understanding of marketing/sales processes.",
      "Ability to work independently and remotely.",
      "Good multitasking skills to be able to maintain relationships and forge new ones at the same time.",
    ],
    jobType: {
      type: "12 months (with the option to extend)",
      note: "This position is set as a 12-month contract, but there is an opportunity for it to continue beyond that initial period with the right candidate. Forager Education is an equal opportunity company and we strongly encourage candidates from diverse backgrounds, including Indigenous candidates. Our founder is an anthropologist by training, and our corporate culture reflects that of multiculturalism and an acceptance of all walks of life.",
    },
    startDate: "01/06/22",
    location: "Remote",
  },
];

export const events = [
  {
    _id: "13B10136202C26F12EE9C305",
    heading: "event 1",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    _id: "345AA2F8C76BA0C3228E5C3E",
    heading: "event 2",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    _id: "3C2254168D93A9828DBC909F",
    heading: "event 3",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
];

export const features = [
  {
    heading: "advanced search tools",
    description:
      "We have developed a Curriculum Search Tool that integrates a full suite of topics including the government curriculum. Now teachers can search by subject, location, theme, Big Ideas, Core Competencies, Curriculum Competencies, and Prescribed Learning Outcomes (PLOs). The VR Voyage Classroom’s dedicated Curriculum Team works with our partners every step of the way to ensure every experience or learning resource is directly aligned with the curriculum.",
  },
  {
    heading: "one source for field trips and presentations",
    description:
      "The Experience Catalogue is a centralized hub of all the exciting field trips and classroom presentations that are offered by programming partners. Our partnership team works with partners to publish the most engaging experiences. No more endless searching online and scrolling through hundreds of individual websites, The VR Voyage Classroom is bringing the full breadth of educational experiences to teachers in one easy-to-use platform.",
  },
  {
    heading: "a database of digital teaching resources",
    description:
      "A resource unlike any other. The VR Voyage Classroom’s Digital Resources Database is a collection of lesson plans and digital resources for students to access and engage with the curriculum at their own pace. Content published to the database can take the form of interactive virtual tours, scavenger hunts, quizzes, video presentations, activity books, digital workbooks, and more. Each is meant to bring the curriculum to life and make learning fun.",
  },
];

export const caseStudies = [
  {
    name: "Duis aute irure dolor",
    description:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    name: "Aute irure dolor duis",
    description:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    name: "Irure dolor Duis aute",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
];

export const contactDetails = [
  {
    icon: "phone",
    heading: "phone / whatsApp",
    body: "+1 250 569 4401",
    action: "tel",
  },
  {
    icon: "mail",
    heading: "general enquiries",
    body: "info@foragereducation.com",
    action: "mailto",
  },
  {
    icon: "globe",
    heading: "sales enquiries",
    body: "sales@foragereducation.com",
    action: "mailto",
  },
  {
    icon: "vr",
    heading: "the vr voyage classroom enquiries",
    body: "partners.ca@foragereducation.com",
    action: "mailto",
  },
  {
    icon: "job",
    heading: "work opportunities",
    body: "careers@foragereducation.com",
    action: "mailto",
  },
];

export const contactReasons = [
  "Grant Assistance",
  "Media Enquiry",
  "Sales Enquiry",
  "Technical Support",
  "The VR Voyage Classroom",
  "Other",
];

export const careerEmail = "careers@foragereducation.com";
