import React from "react";
import { Link } from "react-router-dom";
import Block from "../components/Block";
import Breathing from "../components/Breathing";
import { valuedPartners } from "../components/data";
import Landing from "../components/Landing";
import Section from "../components/Section";
import SectionItem from "../components/SectionItem";
import { SpecialThanks } from "../components/SpecialThanks";

const OurStoryScreen = () => {
  return (
    <>
      <Landing
        bg="story"
        heading="the forager education story"
        subheading="what drives us as an organization"
      />
      <Breathing
        color="white"
        text="At Forager Education, we have a mission to innovate the education sector and provide professional curriculum expertise to cultural organizations searching for ways to better engage their collections in a classroom setting. We seek to build a cultural sector that has the skills and resources to promote the shared history housed within their collections."
      />
      <Block visual="inspiration" film="dark">
        <Section
          bar
          barColor="green"
          subheading="our story"
          heading="our inspiration"
          headingColor="white"
          copies={[
            "We were inspired by conversations with museum curators during the beginning of the COVID-19 pandemic. In the face of losses in funding, staffing shortages, and general capacity issues, no one gave up. The passion for preserving and promoting unique histories shone through even during the hardest times. From those conversations, Forager Education was born. Our founder, Bryce Mathew  Watts, an anthropologist and ethnobotanist, wanted to find a way to get involved in the recovery through collaboration and industry development opportunities.",
          ]}
          copiesColor="white"
        >
          <></>
        </Section>
        <Section
          heading="the journey here"
          headingColor="white"
          copies={[
            "Partnering first with the ~BC Museums Association~, Forager Education began work on developing The VR Voyage Classroom as an outlet for educational programming that museums across British Columbia were developing. Through the support of the Association and Futurpreneur Canada, Forager Education embarked on a year-long research and development journey to create The VR Voyage Classroom and the curriculum services offered to our partners.",
            "Now with The VR Voyage Classroom live and our team of educators in place, we are launching our next phase of programming. Working with a significant number of British Columbia’s cultural institutions, we are bringing together curriculum development programs with audience generation and monetization opportunities to build a stronger cultural sector through collaboration, funding connection, and digital support services. This unique business model was one of the reasons that Forager Education and founder Bryce Mathew Watts were selected as official Canadian delegates to the G20 YEA Summit in Milan, Italy in 2021.",
          ]}
          copiesColor="white"
        >
          <></>
        </Section>
        <Section
          heading="decolonizing
          the classroom"
          headingColor="white"
          copies={[
            "Forager Education is committed to following the recommendations of the Truth & Reconciliation Commission and the initiatives many museums are taking across the country to decolonize their collections. The Commission has a mandate to inform Canadians about the residential school system and its ongoing legacy. There are two specific Calls to Action that Forager Education uses as a driving force for the commitment to diversity, inclusiveness, and reconciliation.",
          ]}
          copiesColor="white"
        >
          <div className="section-items h-section-items">
            <SectionItem
              left
              icon="62"
              heading="call to action 62"
              body="In collaboration with Aboriginal communities, create age-appropriate curriculum resources on residential schools, Treaties, and Aboriginal peoples’ contributions to Canada."
            />
            <SectionItem
              left
              icon="63"
              heading="call to action 63"
              body="Commit to developing curriculum resources on Aboriginal peoples in Canadian history that build student capacity for intercultural understanding, empathy, and mutual respect."
            />
          </div>
        </Section>
      </Block>
      <Block visual="give_back" bgFix>
        <img
          className="book give-back-book"
          alt="book"
          src="./images/icon-pages.png"
          style={{
            position: "absolute",
            right: "0",
            marginTop: "0",
            transform: "rotateY(180deg)",
          }}
        />
        <div className="section-divider"></div>
        <Section
          bar
          barColor="white"
          heading="forager gives back"
          headingColor="white"
        >
          <div style={{ marginBottom: "100px" }}></div>
        </Section>
        <Section
          copies={[
            "Our mandate is to provide support and community development opportunities for the industries that we work in, that is why Forager Education is proud to follow in the tradition of all Forager network companies in pledging at least 1% of all revenue to charitable causes in the communities where we work.",
          ]}
          copiesColor="white"
        >
          <p style={{ color: "var(--var-color-fed-white)" }}>
            To find out more about the program or how to apply for funding,{" "}
            <Link to="/contact">please contact us</Link>.
          </p>
        </Section>
        <img className="book" alt="book" style={{ display: "none" }} />
      </Block>
      <SpecialThanks
        heading="special thanks to our supporters"
        collection={valuedPartners}
      />
    </>
  );
};

export default OurStoryScreen;
