import React, { useEffect, useState } from "react";

const Message = ({ body, type }) => {
  const [display, setDisplay] = useState("block");

  useEffect(() => {
    setDisplay("block");
    setTimeout(() => setDisplay("none"), 5000);
  }, [body]);

  return (
    <div
      className="message"
      style={{ 
        backgroundColor: "transparent",
        position: "fixed",
        display: `${display}`,
        zIndex: "500000"
      }}
    >
      <div className="container">
        <div
          className="message-body" 
          style={
            type === "info"
              ? {
                  color: "var(--var-color-fed-white)",
                  backgroundColor: "var(--var-color-fed-navy)",
                }
              : {}
          }
        >
          <h6 style={{ textTransform: "none" }}>
            {type === "info" ? body : `error: ${body}`}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Message;
