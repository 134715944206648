import React from "react";
import Block from "../components/Block";
import { jobs } from "../components/data";
import Landing from "../components/Landing";
import Section from "../components/Section";
import SectionItem from "../components/SectionItem";

const WorkWithUsScreen = () => {
  return (
    <>
      <Landing
        bg="work"
        heading="work with us"
        subheading="our team ethos and current opportunities"
        align="left"
      />
      <Block visual="team" film="dark">
        <div className="section-divider"></div>
        <div className="section-divider"></div>
        <div className="section-divider"></div>
        <div className="section-divider"></div>
        <div className="section-divider"></div>
        <div className="section-divider"></div>
        <div className="section-divider"></div>
        <div className="section-divider"></div>
        <Section
          bar
          barColor="green"
          subheading="what it's like to work with us"
          heading="our team ethos"
          headingColor="white"
          copies={[
            "We are a dynamic team working remotely from all corners of Canada. The Forager Education team is made up of teachers, museum professionals, anthropologists, Indigenous educators, and programming specialists. We always search for opportunities to build new skills and participate in team-building activities to work effectively together and for our partners.",
          ]}
          copiesColor="white"
        >
          <div className="section-items">
            <SectionItem
              icon="diversity"
              heading="our emphasis on diversity"
              body="Forager Education is dedicated to creating a strong and collaborative work environment that draws on the diversity of our team and appreciates the unique perspectives and lived experiences of each member. We welcome applications from individuals of diverse backgrounds and needs, with the skills to bring our vision to life."
            />
          </div>
        </Section>
      </Block>
      <Block>
        <Section
          bar
          barColor="navy"
          heading="browse our current openings below"
          headingColor="navy"
          noDivider
        >
          <div className="section-items" style={{ gap: "20px" }}>
            {jobs && jobs.length > 0 && (
              <>
                {jobs.map((ele) => (
                  <SectionItem
                    key={ele._id}
                    icon="job"
                    heading={ele.title.toLowerCase()}
                    body={ele.description.join("\n")}
                    cut
                    job
                    type="work-with-us"
                    link={ele._id}
                  />
                ))}
              </>
            )}
          </div>
        </Section>
      </Block>
    </>
  );
};

export default WorkWithUsScreen;
