import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";
import Header from "./components/Header";
import Footer from "./components/Footer.js";

import NotFoundScreen from "./screens/NotFoundScreen";

import HomeScreen from "./screens/HomeScreen";
import OurStoryScreen from "./screens/OurStoryScreen";
import OurServicesScreen from "./screens/OurServicesScreen";
import BCMAScreen from "./screens/BCMAScreen";
// import OurPortfolioScreen from "./screens/OurPortfolioScreen";
// import TeamScreen from "./screens/TeamScreen";
import TheVRCScreen from "./screens/TheVRCScreen";
import WorkWithUsScreen from "./screens/WorkWithUsScreen";
import JobScreen from "./screens/JobScreen";
// import PressScreen from "./screens/PressScreen";
import ContactScreen from "./screens/ContactScreen";
import AdminScreen from "./screens/AdminScreen";

const App = () => {
  if (!window.location.host.includes("localhost")) {
    if (!window.location.protocol.includes("s")) {
      window.location.replace(
        `https://${window.location.host}${
          window.location.pathname.length > 1
            ? `/${window.location.pathname}`
            : ""
        }`,
      );
    }
  }

  return (
    <Router>
      <>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route path="/" component={HomeScreen} exact />
          <Route path="/our-story" component={OurStoryScreen} exact />
          <Route path="/our-services" component={OurServicesScreen} exact />
          {/* <Route path="/our-portfolio" component={OurPortfolioScreen} exact /> */}
          {/* <Route path="/team" component={TeamScreen} exact /> */}
          <Route path="/work-with-us" component={WorkWithUsScreen} exact />
          <Route path="/work-with-us/:id" component={JobScreen} exact />
          <Route
            path="/the-vr-voyage-classroom"
            component={TheVRCScreen}
            exact
          />
          {/* <Route path="/press" component={PressScreen} exact /> */}
          <Route path="/contact" component={ContactScreen} exact />
          <Route path="/bcma" component={BCMAScreen} exact />
          <Route path="/admin" component={AdminScreen} exact />

          <Route component={NotFoundScreen} />
        </Switch>
        <Footer />
      </>
    </Router>
  );
};

export default App;
