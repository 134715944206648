import React from "react";
import { Link } from "react-router-dom";

const Landing = ({
  bg,
  align,
  heading,
  subheading,
  btn,
  link,
  anchor,
  vrc,
}) => {
  return (
    <div
      className="landing"
      style={{
        backgroundImage: bg && `url(./images/landing-${bg}.png)`,
      }}
    >
      {bg !== "main" && (
        <>
          <img
            className="overlay"
            alt="landing-overlay"
            src="./images/landing-overlay.png"
            style={{ transform: align === "right" ? "" : "rotateY(180deg)" }}
          />
          {!vrc && <div className="film"></div>}
          <div className="film"></div>
        </>
      )}
      <div className="container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "50px",
          }}
        >
          <div
            className="landing-content"
            style={{
              justifyContent: align === "right" ? "flex-end" : "flex-start",
            }}
          >
            <div>
              <h1>{heading}</h1>
              <h2>{subheading}</h2>
            </div>
          </div>
          {btn && link && (
            <Link className="btn" to={`/${link}`}>
              {btn}
            </Link>
          )}
          {btn && anchor && (
            <a className="btn" href={anchor} target="_blank" rel="noreferrer">
              {btn}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Landing;
