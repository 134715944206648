import React from "react";
import { Link, Route } from "react-router-dom";

const Section = ({
  bar,
  barColor,
  subheading,
  heading,
  headingColor,
  copies,
  copiesColor,
  btn,
  link,
  anchor,
  noDivider,
  childrenContainerStyles,
  ...rest
}) => {
  return (
    <div className="section">
      <div className="section-title">
        {bar && (
          <div
            className="bar"
            style={{ backgroundColor: `var(--var-color-fed-${barColor})` }}
          ></div>
        )}
        {subheading && <h6 className="section-subheading">{subheading}</h6>}
        {heading && (
          <h2
            className="section-heading"
            style={{
              color: headingColor ? `var(--var-color-fed-${headingColor})` : "",
            }}
          >
            {heading}
          </h2>
        )}
      </div>
      <div className="section-description">
        {copies &&
          copies.map((copy, index) => (
            <p key={index} style={{ color: copiesColor ? copiesColor : "" }}>
              {copy.split("~").map((ele, index) =>
                ele === "BC Museums Association" ? (
                  <Link
                    to="/bcma"
                    key={index}
                    style={{ color: "var(--var-color-fed-green)" }}
                  >
                    BC Museums Association
                  </Link>
                ) : (
                  ele
                ),
              )}
            </p>
          ))}
        {btn && link && (
          <Link className="btn" to={`/${link}`}>
            {btn}
          </Link>
        )}
        {btn && anchor && (
          <a className="btn" href={anchor} target="_blank" rel="noreferrer">
            {btn}
          </a>
        )}
        {!noDivider && <div className="section-divider"></div>}
        {rest && (
          <div className="section-children" style={childrenContainerStyles}>
            <Route {...rest} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Section;
