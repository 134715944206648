import React, { useEffect, useState } from "react";
import ValuedPartner from "./ValuedPartner";

export const SpecialThanks = ({ bgColor, heading, collection, count }) => {
  const [current, setCurrent] = useState(3);
  const [length, setLength] = useState(0);

  const prevSlide = () => {
    if (count) {
      setCurrent(current === 2 ? length - 3 : current - 1);
    } else {
      setCurrent(current === 0 ? length - 1 : current - 1);
    }
  };
  const nextSlide = () => {
    if (count) {
      setCurrent(current === length - 3 ? 2 : current + 1);
    } else {
      setCurrent(current === length - 1 ? 0 : current + 1);
    }
  };

  useEffect(() => {
    setLength(collection.length);
  }, [collection]);

  return (
    <div
      className="special-thanks"
      style={{
        backgroundColor: bgColor ? `var(--var-color-fed-${bgColor})` : "",
      }}
    >
      <div className="container">
        <div className="section-title">
          <div
            className="bar"
            style={{ backgroundColor: `var(--var-color-fed-green)` }}
          ></div>
          <h2
            className="section-heading"
            style={{ color: "var(--var-color-fed-green)" }}
          >
            {heading}
          </h2>
        </div>

        {collection && length && (
          <div className="testimonials" style={{ padding: "25px 0" }}>
            <button onClick={prevSlide} className="arrow">
              <span></span>
            </button>
            <div className="testimonials-slider">
              {count && (
                <ValuedPartner
                  visual={`${
                    collection[current === 0 ? length - 2 : current - 2].name
                  }`}
                  name={
                    collection[current === 0 ? length - 2 : current - 2].name
                  }
                  link={
                    collection[current === 0 ? length - 2 : current - 2].link
                  }
                  small
                />
              )}
              <ValuedPartner
                visual={`${
                  collection[current === 0 ? length - 1 : current - 1].name
                }`}
                name={collection[current === 0 ? length - 1 : current - 1].name}
                link={collection[current === 0 ? length - 1 : current - 1].link}
                medium
              />
              <ValuedPartner
                visual={`${collection[current].name}`}
                name={collection[current].name}
                link={collection[current].link}
                active
              />
              <ValuedPartner
                visual={`${
                  collection[current === length - 1 ? 0 : current + 1].name
                }`}
                name={collection[current === length - 1 ? 0 : current + 1].name}
                link={collection[current === length - 1 ? 0 : current + 1].link}
                medium
              />
              {count && (
                <ValuedPartner
                  visual={`${
                    collection[current === length - 2 ? 0 : current + 2].name
                  }`}
                  name={
                    collection[current === length - 2 ? 0 : current + 2].name
                  }
                  link={
                    collection[current === length - 2 ? 0 : current + 2].link
                  }
                  small
                />
              )}
            </div>
            <button onClick={nextSlide} className="arrow">
              <span></span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
