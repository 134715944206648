import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

function ScrollToTop({ history }) {
  const [display, setDisplay] = useState("none");

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    document.addEventListener(
      "scroll",
      function (e) {
        if (window.innerWidth > 1299) {
          if (window.pageYOffset !== 0) {
            setDisplay("block");
          } else {
            setDisplay("none");
          }
        }
      },
      true
    );
  }, [display]);
  return (
    <button
      className="btn scroll-to-top"
      style={{
        display: `${display}`,
      }}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    ></button>
  );
}

export default withRouter(ScrollToTop);
