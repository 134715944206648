import React, { useState } from "react";
import { Link } from "react-router-dom";

const SectionItem = ({
  icon,
  heading,
  body,
  bodyColor,
  multi,
  left,
  cut,
  job,
  type,
  link,
  action,
}) => {
  const [hover, setHover] = useState(false);

  const hoverOn = () => {
    setHover(true);
  };
  const hoverOff = () => {
    setHover(false);
  };

  return (
    <div
      className="section-item card"
      style={{
        padding: job ? "50px" : "",
        backgroundColor: job ? "var(--var-color-fed-navy)" : "",
        borderRadius: job ? "20px" : "",
        cursor: job ? "pointer" : "",
        backgroundImage:
          job && hover
            ? "linear-gradient(to bottom left, var(--var-color-fed-green) -100%, var(--var-color-fed-navy) 100%)"
            : "",
      }}
      onMouseEnter={hoverOn}
      onMouseLeave={hoverOff}
    >
      {icon && (
        <img
          className="item-icon"
          alt="icon"
          src={`./images/icon-${icon}.png`}
        />
      )}
      <div className="item-text">
        <div className="bar"></div>
        <h6>{heading}</h6>
        {multi ? (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {body.split("\n").map((ele, index) => (
              <p
                key={index}
                style={{
                  color: bodyColor ? `var(--var-color-fed-${bodyColor})` : "",
                  textAlign: left ? "left" : "",
                }}
              >
                {ele}
              </p>
            ))}
          </div>
        ) : (
          <p
            style={{
              color: bodyColor ? `var(--var-color-fed-${bodyColor})` : "",
              textAlign: left ? "left" : "",
            }}
          >
            {cut && body.length > 200 ? `${body.substring(0, 200)}...` : body}
            {cut && body.length > 200 && (
              <span style={{ fontFamily: "var(--var-quicksand-bold)" }}>
                {" "}
                read more
              </span>
            )}
          </p>
        )}
      </div>
      {job && link && (
        <Link className="job-link" to={`/${type}/${link}`}></Link>
      )}
      {action && (
        <a className="job-link" href={`${action}:${body}`}>
          {" "}
        </a>
      )}
    </div>
  );
};

export default SectionItem;
