import React, { useState } from "react";
import { Link } from "react-router-dom";

import { navMenu, navAboutSubMenu } from "./data";

const Header = () => {
  const [mobileNavView, setMobileNavView] = useState("none");

  const displayMobileNav = () => {
    setMobileNavView("flex");
  };

  const hideMobileNav = () => {
    setMobileNavView("none");
  };

  return (
    <div id="header">
      <div className="container">
        <div className="header-content">
          <div className="logo">
            <Link to="/">
              <img
                alt="forager education logo"
                src={`${window.location.origin}/images/logo.png`}
              />
            </Link>
          </div>
          <ul className="main-menu">
            <li className="main-menu-item">
              <span>about</span>
              <ul className="about-dropdown-menu">
                {navAboutSubMenu.map((ele, index) => (
                  <li key={index} className="main-menu-item">
                    <Link to={`/${ele.split(" ").join("-")}`}>{ele}</Link>
                  </li>
                ))}
              </ul>
            </li>
            {navMenu.map((ele, index) => (
              <li key={index} className="main-menu-item">
                <Link to={`/${ele.split(" ").join("-")}`}>{ele}</Link>
              </li>
            ))}
          </ul>
          <div className="hamburger-menu" onClick={displayMobileNav}>
            <div className="buns">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <ul
            className="main-menu-2"
            style={{
              display: mobileNavView,
            }}
            onClick={hideMobileNav}
          >
            {navAboutSubMenu.map((ele, index) => (
              <li
                key={index}
                className="main-menu-item"
                onClick={hideMobileNav}
              >
                <Link to={`/${ele.split(" ").join("-")}`}>{ele}</Link>
              </li>
            ))}
            {navMenu.map((ele, index) => (
              <li
                key={index}
                className="main-menu-item"
                onClick={hideMobileNav}
              >
                <Link to={`/${ele.split(" ").join("-")}`}>{ele}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
