import React from "react";
import Landing from "../components/Landing";
import Breathing from "../components/Breathing";
import Block from "../components/Block";
import Section from "../components/Section";
import SectionItem from "../components/SectionItem";
import DrivenService from "../components/DrivenService";
// import Testimonials from "../components/Testimonials";

const HomeScreen = () => {
  return (
    <>
      <Landing
        bg="main"
        heading="forager education"
        subheading="empowering innovation in the classroom"
      />
      <Breathing
        color="green"
        text="Forager Education is innovating the way curriculum-connected programming is developed by cultural institutions and experienced by students. Working with a network  of Indigenous, cultural, scientific, and artistic institutions, we are creating a new era of experiential learning for in-class and distance learning."
        btn="kickstart your programming"
        link="contact"
      />
      <Block>
        <Section
          bar
          barColor="green"
          subheading="for educators, by educators"
          heading="educator driven services"
          copies={[
            "Whether our partners are small rural institutions or large provincial associations, the curriculum development services that Forager Education offers are tailored to the exact needs of each. Our attention to detail and level of dedication to each project ensures that the entire cultural sector benefits from the unique stories that our team helps to amplify. Through lesson planning, digital adaptation, and relationship building, our services are focused on student engagement and interactivity. Our team is made up of Indigenous educators, teachers, and program specialists that craft the highest quality educational resources for our partners.",
          ]}
          btn="explore services"
          link="our-services"
        >
          <div className="driven-services">
            {[
              {
                heading: "curriculum review",
                description:
                  "Most organizations have some educational programs developed already, but depending on who made them, when they were developed, and for what grade, they might need a second glance. Forager’s Curriculum Coordinators live and breathe the provincial and territorial curriculum. They will work with you to review your existing programs to ensure they match the curriculum, are linked with the right grades and are optimized for student engagement.",
              },
              {
                heading: "lesson plan development",
                description:
                  "Your institution specializes in a unique aspect of our shared history, let’s share that with the classroom. If you don’t know where to begin or are simply wanting extra help, Forager’s Curriculum Development Team will work with your institution to develop a complete curriculum-connected lesson plan ready to use for direct engagement with classes. Depending on your requirements, these can be simple PDFs for teachers to use or complex interactive programs that involve a complete set of activities for classes to really delve deep into the subject matter.",
              },
              {
                heading: "field trip programming",
                description:
                  "In-person learning is a great way to engage with students and let them learn without feeling like they are being lectured on a topic. Forager’s Experience Team will take your idea and help craft activities and discussions relating to your collection in a fun and engaging way. The complete program will be developed along with a final report turned over with scripts and instructions for your in-house team to effectively run this program.",
              },
              {
                heading: "in-class presentations development",
                description:
                  "Why make the class travel to your museum when you can bring the museum to the classroom. Forager’s Experience Team will work with your curators to develop a travelling program. Building off the government curriculum, we will create an engaging in-class experience for students to learn directly from the experts in your organization. Scripts, visuals, and other interactive activities will be developed for your team to know exactly what to do.",
              },
              {
                heading: "learning kit creation",
                description:
                  "Not all cultural institutions are reachable for in-person learning. With Forager Education’s Learning Kit Creation, your museum, art gallery, or cultural centre can develop an in-class learning experience for teachers to conduct themselves. These boxes will be complete with a detailed instruction booklet for the teacher to understand the program, activities, games, and any other physical materials that will bring the curriculum to life through your unique institution’s collection.",
              },
            ]
              .splice(0, 4)
              .map((ele, index) => (
                <DrivenService key={index} name={ele.heading} />
              ))}
          </div>
        </Section>
      </Block>
      <Block visual="specialization" film="dark">
        <Section
          bar
          barColor="green"
          subheading="shared passion for heritage"
          heading="our specialization"
          headingColor="white"
          copies={[
            "Founded by an anthropologist and ethnobotanist, Forager Education has a passion for cultural and environmental institutions. We specialize in creating programming solutions for museums, cultural centres, and science centres that are the perfect blend of informative, engaging, and historically and scientifically accurate.",
          ]}
          copiesColor="white"
        >
          <div className="section-items">
            <SectionItem
              left
              icon="featured"
              heading="a commitment to excellence"
              body="We work directly with teachers to ensure the resources are the right fit"
            />
            <SectionItem
              left
              icon="calendar"
              heading="a dedication to quality work"
              body="We set achievable timelines for our partners and work tirelessly to meet deadlines"
            />
            <SectionItem
              left
              icon="book"
              heading="a passion for research"
              body="We utilize our strong research skills to bring together unique stories and resources"
            />
          </div>
        </Section>
      </Block>
      {/* <Testimonials /> */}
      <Block visual="netflix" film="dark" book>
        <Section
          bar
          barColor="green"
          subheading="collaborative e-learning"
          heading="the vr voyage classroom"
          headingColor="white"
          copies={[
            "We have developed this education platform as a community space for the cultural sector to bring their programming together and engage with classrooms through shared promotion and outreach activities. Field trips, presentations, and digital resources bring first-hand knowledge to the full curriculum ranging from ancient history to modern politics, plate tectonics to habitat restoration. The VR Voyage Classroom is an innovative tool to boost the educational experience for students, but also to build capacity within institutions through monetized content.",
          ]}
          copiesColor="white"
          btn="learn about our platform"
          link="the-vr-voyage-classroom"
        >
          <></>
        </Section>
      </Block>
      <Block visual="commitment_inclusion" film="light" height="640">
        <Section
          bar
          barColor="navy"
          heading="a commitment to inclusion"
          copies={[
            "Incorporating more diverse stories into the classroom curriculum isn’t where we stop. At Forager Education, we are dedicated to including all voices in our organization and creating a diverse team that brings different perspectives to the work we do. We strive to create a collaborative work environment that honours the knowledge that goes into each lesson plan and educational resource and one that uses the Truth and Reconciliation Commission and UNDRIP as a guiding force in its work as well.",
          ]}
          btn="our community policy"
          link="the-vr-voyage-classroom"
        >
          <></>
        </Section>
      </Block>
    </>
  );
};

export default HomeScreen;
