import React from "react";
import { Link } from "react-router-dom";
import { footerLinks, footerSocials } from "./data";
import SectionItem from "./SectionItem";

const Footer = () => {
  const date = new Date();

  return (
    <div className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="identity">
            <div className="about">
              <SectionItem
                heading="forager education"
                body="empowering innovation in the classroom"
              />
              <p>
                A team of innovative educators and academics reimagining the
                learning experience through new collaborative development
                processes and technological trends. Together we are developing
                stronger programming for the cultural sector.
              </p>
              <div className="social">
                <h5>follow us</h5>
                <div className="social-icons">
                  {footerSocials.map((ele, index) => (
                    <a
                      key={index}
                      href={ele.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt={`${ele}-link`}
                        src={`${window.location.origin}/images/icon-${ele.icon}.png`}
                      />
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="footer-section">
              <h4>contact</h4>
              <div>
                <h6>company hq</h6>
                <p>Gibsons BC, Canada</p>
              </div>
              <div>
                <h6>phone</h6>
                <a href="tel:12505694401">+1 250 569 4401</a>
              </div>
              <div>
                <h6>general inquiries</h6>
                <a href="mailto:info@foragereducation.com">
                  info@foragereducation.com
                </a>
              </div>
              <div>
                <h6>careers</h6>
                <a href="mailto:careers@foragereducation.com">
                  careers@foragereducation.com
                </a>
              </div>
            </div>
            <div className="footer-section">
              <h4>navigation</h4>
              <ul>
                {footerLinks.map((ele, index) => (
                  <li key={index}>
                    <Link
                      to={
                        ele === "homepage"
                          ? "/"
                          : `/${ele.split(" ").join("-")}`
                      }
                    >
                      {ele}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="copyright">
            <span>
              {`copyright © ${date.getFullYear()}`}, forager international ltd
            </span>
            <a
              className="powered"
              href="https://foragermediagroup.com/"
              target="_blank"
              rel="noreferrer"
            >
              <span>website created by</span>
              <p>forager</p>
              <span>| digital</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
