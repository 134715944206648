import React from "react";

import Block from "../components/Block";
import Landing from "../components/Landing";
import Section from "../components/Section";

const BCMAScreen = () => {
  return (
    <>
      <Landing
        bg="bcma"
        heading="a partnership with the bcma"
        subheading="Working together to find innovative solutions to build a stronger heritage sector in British Columbia"
      />
      <Block visual="bcma" film="dark">
        <Section
          heading="the journey here"
          headingColor="white"
          copies={[
            "The VR Voyage Classroom was developed by Forager Education in partnership with the BC Museums Association (BCMA). With a wealth of programs across British Columbia and so many heritage organizations facing problems during the pandemic from funding decreases, closure to the public, and access to a full workforce, Forager Education’s founder, Bryce Mathew Watts, reached out to the BCMA with the idea for a community-driven hub for educational experiences and engaging learning resources developed by the holders of those heritage stories. From those early brainstorming sessions in 2020, the BCMA was fully onboard and together, Forager Education and the BCMA worked at finding funding, signing on heritage partners, and developing the initial platform.",
            "Through the generous support of Futurpreneur Canada, the BDC, and Canada Council for the Arts, the Phase 1 platform was designed and launched in September 2022. Working with a significant number of heritage partners around British Columbia, the platform is creating connections between teachers and these keepers of natural and cultural heritage.",
          ]}
          copiesColor="white"
        >
          <></>
        </Section>
        <Section
          heading="The Role of the BC Museums Association"
          headingColor="white"
          copies={[
            "As a key partner of The VR Voyage Classroom, the BCMA is the point of contact between the platform and school districts across British Columbia. Utilizing their organization recognition within the provincial government and local municipalities, they are building connections that bring the platform’s teacher audience onboard. With one of the main visions of The VR Voyage Classroom being capacity building at all levels of the heritage sector, the BCMA’s participation in the platform and getting school boards signed up means that they benefit from the success of the platform as well as the individual heritage organizations creating the content to publish to the platform.",
          ]}
          copiesColor="white"
          childrenContainerStyles={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: "flex-end",
          }}
        >
          <p className="quote">
            <em>
              “The VR Voyage Classroom platform is a win/win for both heritage
              organizations and educators. Museums, galleries, and heritage
              sites get the opportunity to reach new audiences with their
              amazing educational programs and educators now have an easy and
              affordable way to bring new heritage experiences into their
              classrooms. The BC Museums Association is a proud partner with
              Forager Education to find innovative ways to magnify the
              transformative power of museums.”
            </em>
          </p>
          <p className="quote-person">
            <em>
              – Ryan Hunt, Executive Director at the BC Museums Association
            </em>
          </p>
        </Section>
        <Section
          heading="About the BC Museums Association"
          headingColor="white"
          copies={[
            "The BC Museums Association was founded in 1957 with the vision to help build a museum community that is valued for providing leadership, dialogue, influence, and knowledge to British Columbians. The BCMA is dedicated to creating a bright future for British Columbia’s museum, gallery, and related heritage communities through networking, advocacy, innovation, and professional development. Since the Association was founded, they have grown to include more than 400 members in every corner of the province representing museums, art galleries, science centres, Indigenous cultural organizations, heritage sites, botanical and zoological gardens, cultural centres, conservation institutes, exhibition galleries permanently maintained by libraries and archives, and nature reserves. The core of their work is to provide programs and services to benefit members and the sector at large.",
          ]}
          copiesColor="white"
        >
          <></>
        </Section>
      </Block>
    </>
  );
};

export default BCMAScreen;
