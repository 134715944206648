import React from "react";

const Product = ({ type, visual, heading, subheading, description, swap }) => {
  return (
    <div
      className="product"
      style={
        window.innerWidth > 1299
          ? {
              flexDirection: swap ? "row-reverse" : "row",
            }
          : {
              flexDirection: "column",
            }
      }
    >
      <img
        alt="visual"
        src={`./images/${type}-${visual.split(" ").join("-")}.png`}
      />
      <div
        className="product-text"
        style={
          window.innerWidth > 1299
            ? { alignItems: swap ? "flex-end" : "flex-start" }
            : {}
        }
      >
        <div
          className="bar"
          style={
            window.innerWidth > 1299
              ? {
                  backgroundColor: "var(--var-color-fed-green)",
                  left: swap ? "auto" : "0",
                  right: swap ? "0" : "auto",
                }
              : { backgroundColor: "var(--var-color-fed-green)", left: "0" }
          }
        ></div>
        <h2 className="product-heading">{heading}</h2>
        {subheading && <h3 className="product-subheading">{subheading}</h3>}
        <p
          className="product-description"
          style={
            window.innerWidth > 1299
              ? { textAlignLast: swap ? "right" : "left" }
              : {}
          }
        >
          {description}
        </p>
      </div>
    </div>
  );
};

export default Product;
