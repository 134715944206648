import React from "react";
import Block from "../components/Block";
import Breathing from "../components/Breathing";
import Landing from "../components/Landing";
import Section from "../components/Section";
import ContactForm from "../components/ContactForm";

const ContactScreen = () => {
  return (
    <>
      <Landing
        bg="contact"
        heading="got an education question?"
        subheading="get in touch today"
        align="right"
      />
      <Breathing
        color="green"
        text="Our team of educators has the curriculum expertise to address your programming needs. If you have questions about lesson plan development, how to create engaging digital classroom programs, or are interested in learning more about The VR Voyage Classroom platform, one of our team will respond with all the information you need."
      />
      <Block>
        <Section
          bar
          barColor="green"
          subheading="choose your preferred method"
          heading="contact details"
        >
          <div className="section-divider"></div>
          <div className="section-divider"></div>
          {window.innerWidth > 1299 ? (
            <>
              <p style={{ fontSize: "var(--menu-links-font-size)" }}>
                Fields marked with an * are required
              </p>
            </>
          ) : (
            <></>
          )}
        </Section>
        <ContactForm />
      </Block>
    </>
  );
};

export default ContactScreen;
