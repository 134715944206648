import React from "react";
import Landing from "../components/Landing";

const NotFoundScreen = () => {
  return (
    <>
      <Landing
        bg="notfound"
        heading="page not found"
        subheading="It looks like this page has moved somewhere else. Come back to the homepage."
        align="left"
        btn="back to home"
        link="/"
      />
    </>
  );
};

export default NotFoundScreen;
