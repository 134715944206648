import React from "react";

const DrivenService = ({ name }) => {
  return (
    <div
      className="service-col"
      style={{
        backgroundImage: `url(./images/driven-services-${name
          .split(" ")
          .join("-")}.png)`,
      }}
    >
      <div className="overlay"></div>
      <div>
        <div className="bar"></div>
        <h4>{name}</h4>
      </div>
    </div>
  );
};

export default DrivenService;
