import React from "react";

const ValuedPartner = ({ name, link, active, small, medium }) => {
  return (
    <>
      {link ? (
        <a
          className={`valued-partner ${active ? "active" : ""} ${
            small ? "small" : ""
          } ${medium ? "medium" : ""}`}
          href={link.length > 0 ? link : `/`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt={name}
            src={`./images/partner-${name.split(" ").join("-")}.png`}
          />
          <h4>{name}</h4>
        </a>
      ) : (
        <div
          className={`valued-partner ${active ? "active" : ""} ${
            small ? "small" : ""
          } ${medium ? "medium" : ""}`}
        >
          <img
            alt={name}
            src={`./images/partner-${name.split(" ").join("-")}.png`}
          />
          <h4>{name}</h4>
        </div>
      )}
    </>
  );
};

export default ValuedPartner;
