import React, { useEffect, useState } from "react";
import Block from "../components/Block";
import { careerEmail, jobs } from "../components/data";
import Section from "../components/Section";
import SectionItem from "../components/SectionItem";

const JobScreen = ({ match }) => {
  const [job, setJob] = useState(
    jobs.find((job) => job._id === match.params.id)
  );

  useEffect(() => {
    setJob(jobs.find((job) => job._id === match.params.id));
  }, [match]);
  return (
    <div style={{ paddingTop: "100px" }}>
      {job && (
        <Block>
          <Section bar barColor="navy" heading={job.title} noDivider>
            <SectionItem
              heading={`description`}
              body={`• ${job.description.join("\n• ")}`}
              bodyColor="navy"
              multi
            />
            <div className="section-divider"></div>
            <div className="section-divider"></div>
            <SectionItem
              heading={`responsibilities`}
              body={`• ${job.responsibilities.join("\n• ")}`}
              bodyColor="navy"
              multi
            />
            <div className="section-divider"></div>
            <div className="section-divider"></div>
            <SectionItem
              heading={`requirements`}
              body={`• ${job.requirements.join("\n• ")}`}
              bodyColor="navy"
              multi
            />
            <div className="section-divider"></div>
            <div className="section-divider"></div>
            <SectionItem
              heading={`job type`}
              body={`type: ${job.jobType.type.concat(
                " \n" + job.jobType.note
              )}`}
              bodyColor="navy"
              multi
            />
            <div className="section-divider"></div>
            <div className="section-divider"></div>
            <SectionItem
              heading={`start date`}
              body={`${job.startDate.concat("")}`}
              bodyColor="navy"
              multi
            />
            <div className="section-divider"></div>
            <div className="section-divider"></div>
            <SectionItem
              heading={`location`}
              body={`${job.location.concat("")}`}
              bodyColor="navy"
              multi
            />
            <p>
              If you are interested in this role, please send your CV to:{" "}
              <a href={`mailto:${careerEmail}`}>{careerEmail}</a>
            </p>
          </Section>
        </Block>
      )}
    </div>
  );
};

export default JobScreen;
