import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createContactMail } from "../actions/contactMailActions";
import { contactDetails, contactReasons } from "./data";
import SectionItem from "./SectionItem";
import Loader from "./Loader";
import Message from "./Message";
import { CONTACTMAIL_CREATE_RESET } from "../constants/contactMailConstants";

const ContactForm = () => {
  const dispatch = useDispatch();

  const contactMailCreate = useSelector((state) => state.contactMailCreate);
  const { loading, error, success } = contactMailCreate;

  const [name, setName] = useState(``);
  const [email, setEmail] = useState(``);
  const [subject, setSubject] = useState(``);
  const [reason, setReason] = useState(`hide`);
  const [message, setMessage] = useState(``);

  const nameHandler = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const subjectHandler = (e) => {
    e.preventDefault();
    setSubject(e.target.value);
  };

  const reasonHandler = (e) => {
    e.preventDefault();
    setReason(e.target.value);
  };

  const messageHandler = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      name.length > 0 &&
      email.length > 0 &&
      subject.length > 0 &&
      reason.length > 0 &&
      reason !== "hide" &&
      message.length > 0
    ) {
      const contactMail = {
        name,
        email,
        subject,
        reason,
        message,
      };

      dispatch(createContactMail(contactMail));
    }
  };

  useEffect(() => {
    if (success) {
      setName(``);
      setEmail(``);
      setSubject(``);
      setReason(``);
      setMessage(``);

      setTimeout(() => {
        dispatch({ type: CONTACTMAIL_CREATE_RESET });
      }, 7000);
    }
  }, [success, dispatch]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message body="Something went wrong. Please make sure all field values are valid" />
      ) : (
        success && (
          <Message body="Message sent successfully. Thank you" type="info" />
        )
      )}
      <div className="section">
        <div className="section-title" style={{ gap: "40px" }}>
          {contactDetails && contactDetails.length > 0 && (
            <>
              {contactDetails.map((ele, index) => (
                <SectionItem
                  key={index}
                  icon={ele.icon}
                  heading={ele.heading}
                  body={ele.body}
                  bodyColor="navy"
                  action={ele.action}
                />
              ))}
            </>
          )}
        </div>
        <div className="section-description">
          {window.innerWidth > 1299 ? (
            <></>
          ) : (
            <>
              <div className="section-divider"></div>
              <div className="section-divider"></div>
              <div className="section-divider"></div>
              <p style={{ fontSize: "var(--menu-links-font-size)" }}>
                Fields marked with an * are required
              </p>
            </>
          )}
          <form onSubmit={submitHandler} className="contact-form">
            <div className="input">
              <div className="bar"></div>
              <p className="input-title">name: *</p>
              <input
                type="text"
                value={name}
                onChange={nameHandler}
                placeholder="enter your name here"
                required
              />
            </div>
            <div className="input">
              <div className="bar"></div>
              <p className="input-title">email: *</p>
              <input
                type="email"
                value={email}
                onChange={emailHandler}
                placeholder="enter your email here"
                required
              />
            </div>
            <div className="input">
              <div className="bar"></div>
              <p className="input-title">subject: *</p>
              <input
                type="text"
                value={subject}
                onChange={subjectHandler}
                placeholder="enter subject here"
                required
              />
            </div>
            <div className="input">
              <div className="bar"></div>
              <p className="input-title">reason for message: *</p>
              <div className="select">
                <select onChange={reasonHandler}>
                  <option value="hide">choose reason</option>
                  {contactReasons && contactReasons.length > 0 && (
                    <>
                      {contactReasons.map((ele, index) => (
                        <option key={index} value={ele}>
                          {ele}
                        </option>
                      ))}
                    </>
                  )}
                </select>
              </div>
            </div>
            <div className="input">
              <div className="bar"></div>
              <p className="input-title">message: *</p>
              <textarea
                onChange={messageHandler}
                placeholder="enter your message here"
                value={message}
              />
            </div>
            <button className="btn" onClick={submitHandler}>
              submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
