import React from "react";
import { Route } from "react-router-dom";

const Showcase = ({ ...rest }) => {
  return (
    <div className="container">
      <div className="showcase">
        <Route {...rest} />
      </div>
    </div>
  );
};

export default Showcase;
